<script>
import { CountTo } from "vue3-count-to";
import { mapGetters } from "vuex";

import Layout from "@/layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import SmsCampaignList from "@/components/campaign/SMSCampaignList.vue";

// import Revenue from "./revenue";
// import SalesLocation from "./sales-location";

export default {
  components: {
    CountTo,
    Layout,
    // Swiper,
    // SwiperSlide,
    PageHeader,
    SmsCampaignList,
  },
  data() {
    return {
      title: "GICAM SMS Dashboard",
      items: [
        {
          text: "SMS Dashboard",
          href: "/",
        },
        {
          text: "GICAM SMS Dashboard",
          active: true,
        },
      ],
    };
  },
  methods: {},
  computed: {
    ...mapGetters("campaigns", ["campaigns"]),
    ...mapGetters("system", ["partner", "isAdmin"]),
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row dash-nft">
      <div class="col-xxl-12">
        <!-- <div class="col-xxl-9"> -->
        <div class="row">
          <div class="col-xl-6">
            <div class="card overflow-hidden">
              <div class="card-body bg-marketplace d-flex align-items-center">
                <div class="flex-grow-1">
                  <h4 class="fs-18 lh-base fw-bold mb-0">
                    Gestion de vos compagnes
                    <span class="text-success">SMS.</span>
                  </h4>
                  <p class="mb-0 mt-2 pt-1 text-muted">
                    Gérez vos campagnes en quelques clics.
                  </p>
                  <div v-if="isAdmin" class="d-flex gap-3 mt-4">
                    <router-link
                      :to="{ name: 'campagnes-sms-create' }"
                      class="btn btn-primary"
                    >
                      Commencer une campagne
                    </router-link>
                    <!-- <router-link
                      :to="'/'"
                      class="btn bg-soft-primary text-primary"
                    >
                      Consulter les archives
                    </router-link> -->
                  </div>
                </div>
                <div
                  class="rounded-circle"
                  style="
                    height: 100px;
                    overflow: hidden;
                    background-color: #106454;
                  "
                >
                  <img
                    src="@/assets/gicam.png"
                    alt=""
                    class="img-fluid"
                    style="height: 100px; padding: 15px"
                  />
                </div>
              </div>
            </div>
          </div>
          <!--end col-->
          <div class="col-xl-3 col-md-12">
            <div class="card card-height-100">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div class="avatar-sm flex-shrink-0">
                    <span class="avatar-title bg-soft-primary rounded fs-3">
                      <i class="bx bx-wallet text-primary"></i>
                    </span>
                  </div>
                  <div class="flex-grow-1 ps-3">
                    <h5 class="text-muted text-uppercase fs-13 mb-0">
                      Total archives
                    </h5>
                  </div>
                </div>
                <div class="mt-4 pt-1">
                  <h4 class="fs-22 fw-semibold ff-secondary mb-0">
                    <count-to
                      :startVal="0"
                      :endVal="campaigns.length"
                      :duration="2000"
                      :prefix="campaigns.length < 9 ? '0' : ''"
                    ></count-to>

                    Campagnes SMS
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-md-12">
            <div class="card card-height-100">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div class="avatar-sm flex-shrink-0">
                    <span class="avatar-title bg-soft-primary rounded fs-3">
                      <i class="bx bx-wallet text-primary"></i>
                    </span>
                  </div>
                  <div class="flex-grow-1 ps-3">
                    <h5 class="text-muted text-uppercase fs-13 mb-0">
                      Solde SMS
                    </h5>
                  </div>
                </div>
                <div class="mt-3 pt-1">
                  <h4 class="fs-22 fw-semibold ff-secondary mb-1">
                    <count-to
                      v-if="partner?.balance"
                      :startVal="0"
                      :endVal="partner.balance ?? 0"
                      :duration="500"
                      :prefix="partner.balance <= 9 ? '0' : ''"
                    ></count-to>

                    <span v-else>-</span>

                    SMS restants
                  </h4>
                  <router-link
                    :to="{ name: 'campagnes-sms-recharge-logs' }"
                    class="text-decoration-underline"
                  >
                    Historique des recharges
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end row-->

        <!-- <div class="col-sm-auto mb-4">
          <div>
            <router-link :to="{ name: 'cases-create' }" class="btn btn-primary">
              <i class="ri-add-line align-bottom me-1"></i>
              Ajouter un document
            </router-link>
          </div>
        </div> -->

        <div class="row mb-3">
          <div class="col-xxl-12 mb-3">
            <!-- <Marketplace /> -->
            <SmsCampaignList />
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end col-->

      <!-- super -->

      <!--end col-->
    </div>
    <!--end row-->
  </Layout>
</template>
